$(function(){
    $("#list-tab a").removeClass("active");

    if(this.location.pathname == '/store/app/publish'){
        $('.publish_link').addClass("active");
    } else if(location.pathname.match("/option")) {
        $('.option_link').addClass("active");
    } else if (location.pathname.match("/store/app")) {
        $('.app_link').addClass("active");
    } else if(location.pathname.match("/store/images")) {
        $('.image_link').addClass("active");
    } else if(location.pathname.match("/store/coupon")) {
        $('.coupon_link').addClass("active");
    } else if(location.pathname.match("/store/notice")) {
        $('.notice_link').addClass("active");
    } else if(location.pathname.match("/store/billing")) {
        $('.bill_link').addClass("active");
    } else {
        $('.store_link').addClass("active");
    } 
});

$(window).on('load', function (){
    

});

$("#list-tab a").on('click', function(){
    $("#list-tab a").removeClass("active");
    $(this).addClass("active");
});